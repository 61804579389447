<template>
  <div class="animated fadeIn container">
    <h1>문자 로그</h1>
    <b-input-group class="mt-4" style="max-width: 300px">
      <b-form-input
        type="text"
        class="form-control"
        placeholder="검색어"
        v-model="searchKeyword"
        @keydown.native="searchKeywordInputKeyDown"
      />
      <b-btn variant="primary" class="fl" @click.prevent="changeSearchKeyword">검색</b-btn>
    </b-input-group>
    <table class="border-table mt-4" id="users-table" v-if="items.length > 0">
      <thead>
        <tr>
          <td width="80px">No.</td>
          <td width="100px">서비스명</td>
          <td width=" 180px">발신번호</td>
          <td width=" 180px">수신번호</td>
          <td>메시지</td>
          <td width="120px">일시</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in items">
          <td>
            {{ item.id }}
          </td>
          <td>
            {{ item.service }}
          </td>
          <td>
            {{ item.srcNumber }}
          </td>
          <td>
            {{ item.dstNumber }}
          </td>
          <td>
            {{ item.message }}
          </td>
          <td>
            {{ item.createdAt | dateFormat }}
          </td>
        </tr>
      </tbody>
    </table>
    <b-pagination
      align="center"
      :total-rows="totalCount"
      v-model="currentPage"
      :per-page="50"
      :limit="10"
      class="mt-4"
      @change="changePage"
    ></b-pagination>
  </div>
</template>

<script>
import ReceiveMessageService from '@/services/ReceiveMessageService'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'

export default {
  name: 'ReceiveMessages',
  components: {
    PulseLoader,
  },
  data() {
    return {
      isLoading: true,
      loaderStyle: {
        color: '#ccc',
        size: '8px',
      },
      items: [],
      totalCount: Number.MAX_SAFE_INTEGER,
      currentPage: null,
      searchKeyword: null,
      canDownload: false,
    }
  },
  created() {
    this.searchKeyword = this.$route.query.searchKeyword
    this.currentPage = parseInt(this.$route.query.page) || 1
    this.getList()
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
  },
  methods: {
    async getList() {
      this.isLoading = true
      var params = {
        page: this.currentPage,
        status: 0,
      }

      if (this.searchKeyword) {
        params.searchKeyword = this.searchKeyword
      }

      const response = await ReceiveMessageService.getList(params)
      if (response.status == 200) {
        this.items = response.data.list
        this.totalCount = response.data.totalCount
      }

      this.isLoading = false
    },
    changePage(page) {
      var query = {
        page: page,
      }

      if (this.searchKeyword && this.searchKeyword.length > 0) {
        query.searchKeyword = this.searchKeyword
      }

      this.$router.push({
        path: '/admin/receiveMessages',
        query: query,
      })
    },
    changeSearchKeyword() {
      this.$router.push({
        path: '/admin/receiveMessages',
        query: {
          searchKeyword: this.searchKeyword,
        },
      })
    },
    searchKeywordInputKeyDown(e) {
      if (e.which === 13) {
        this.changeSearchKeyword()
      }
    },
  },
  watch: {
    '$route.query.searchKeyword'() {
      this.searchKeyword = this.$route.query.searchKeyword
      this.getList()
    },
    '$route.query.page'() {
      this.currentPage = this.$route.query.page
      this.getList()
    },
  },
}
</script>
<style scoped></style>
